import { css } from 'emotion';
import styled from 'react-emotion';
import media from 'utils/mediaqueries';
import ButtonBack from 'components/ButtonBack';

export const SliderSection = styled('section')({
  position: 'relative',
  padding: '20px 0 0',
  bottom: 0,
  height: 'auto',
  '.flickity-button': {
    top: 'calc(50% - 40px)',
  },
});

export const Slide = styled('div')({
  outline: 'none',
  width: '100%',
  height: 520,
  [media(540)]: {
    width: '50%',
  },
  [media(860)]: {
    width: '33.33%',
  },
  [media('md')]: {
    width: '25%',
  },
  [media(1270)]: {
    width: '20%',
  },
  [media(1600)]: {
    width: '16.66%',
  },
});

export const employeeProfileOpenStyle = css({
  outline: 'none',
  paddingTop: '50px',
  [media(860)]: {
    paddingTop: '80px',
  },
  [media('md')]: {
    paddingTop: '120px',
  },
  [Slide]: {
    width: '100%',
    cursor: 'default',
    height: 'auto',
    paddingBottom: 100,
    [media(860)]: {
      paddingBottom: 0,
      height: 'calc(100vh - 80px)',
      minHeight: '500px',
      maxHeight: '800px',
    },
    [media('md')]: {
      height: 'calc(100vh - 120px)',
    },
  },
  '.employeeContainer': {
    height: 'auto',
    display: 'block',
    img: {
      display: 'none',
    },
    [media(860)]: {
      display: 'flex',
      position: 'absolute',
      height: '100%',
      bottom: 0,
      width: '42%',
      img: {
        display: 'block',
        paddingTop: 0,
      },
    },
    [media('md')]: {
      width: '50%',
    },
  },
  '.nameButton': {
    display: 'none',
    fontSize: 28,
    pointerEvents: 'none',
    textDecoration: 'none',
    bottom: '100px',
    svg: {
      display: 'none',
    },
    [media(860)]: {
      display: 'block',
    },
  },
  '.headline': {
    marginTop: 50,
    display: 'inline-block',
    [media(860)]: {
      display: 'none',
    },
  },
  '.flickity-button': {
    display: 'none',
    [media(860)]: {
      display: 'block',
    },
  },
});

export const ButtonBackStyled = styled(ButtonBack)({
  left: 30,
  top: 40,

  [media(860)]: {
    left: 50,
  },

  [media('md')]: {
    top: 90,
  },
});
