import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Link from 'components/Link';
import { styled } from 'utils/emotion';
import media from 'utils/mediaqueries';
import Container from 'components/Container';
import Image from 'components/Image';
import TeaserTextBoxSection from 'components/TeaserTextBoxSection';

const NewsSection = styled('section')({
  position: 'relative',
  backgroundColor: '#fff',
  padding: '43% 20px 40px',

  [media(400)]: {
    padding: '39% 20px 40px',
  },

  [media('sm')]: {
    paddingBottom: '80px',
  },

  [media('md')]: {
    padding: '0 40px 100px',
    height: 'auto',
    minHeight: 700,
  },

  '> div:first-child': {
    paddingTop: '33.33%',

    [media('md')]: {
      paddingTop: '480px',
    },
  },
});

function NewsLatest({ data, headline, image, text }) {
  const entries = data?.craft?.entries;
  return (
    <NewsSection>
      <Image
        outerWrapperClassName="test"
        src={image?.[0]?.full}
        alt={headline}
        aspectRatio="1:4"
        position="absolute"
        top="0"
        left="0"
      />
      <Container position="relative" display="flex">
        {entries && (
          <TeaserTextBoxSection
            headline={headline}
            text={text}
            entries={entries}
          />
        )}
      </Container>
    </NewsSection>
  );
}

export default props => (
  <StaticQuery
    query={graphql`
      query QueryNewsLatest {
        craft {
          entries(section: [news], limit: 2, orderBy: "postDate DESC") {
            id
            title
            postDate
            fullUri
            ... on Craft_News {
              newsContent {
                content
              }
            }
          }
        }
      }
    `}
    render={data => <NewsLatest data={data} {...props} />}
  />
);
