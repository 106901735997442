import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import scrollToElement from 'scroll-to-element';
import SliderFlickity from 'components/SliderFlickity';
import theme from 'components/Layout/themeDefault';
import { bgGradientBlueLight } from 'components/Gradients';

import ProfileCard from './ProfileCard';
import Person from './Person';
import {
  SliderSection,
  Slide,
  employeeProfileOpenStyle,
  ButtonBackStyled,
} from './styles';

class TeamSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: false,
      isDesktop: false,
      isEmployeeProfileOpenStyle: false,
      slidePositionOnOpen: 0,
    };
  }

  componentDidUpdate = () => {
    this.teamSliderResize();
  };

  componentWillMount() {
    if (typeof window !== 'undefined') {
      this.setMobileState();
      window.addEventListener('resize', this.handleWindowSizeChange);
    }
  }

  handleWindowSizeChange = () => {
    this.setMobileState();
  };

  setMobileState() {
    if (window.innerWidth < theme.breakpoints.lg) {
      this.setState({
        isMobile: true,
        isDesktop: false,
      });
    } else {
      this.setState({
        isMobile: false,
        isDesktop: true,
      });
    }
  }

  handleEmployeeNameClick = e => {
    const { isEmployeeProfileOpenStyle } = this.state;
    if (!isEmployeeProfileOpenStyle) {
      this.openEmployeeProfile();
      this.teamSlider.select(e.target.id, false, false);
    }
  };

  handleEmployeeProfileCloseClick = () => {
    const { slidePositionOnOpen } = this.state;
    this.teamSlider.select(slidePositionOnOpen, false, false);
    this.closeEmployeeProfile();
  };

  teamSliderResize = () => {
    this.teamSlider.resize();
  };

  openEmployeeProfile() {
    this.setState({
      slidePositionOnOpen: this.teamSlider.selectedIndex,
    });
    this.scrollSliderIntoViewport();
    this.setState({
      isEmployeeProfileOpenStyle: true,
    });
  }

  closeEmployeeProfile() {
    this.setState({
      isEmployeeProfileOpenStyle: false,
    });
  }

  scrollSliderIntoViewport() {
    const { id } = this.props;

    scrollToElement(`#${id}`, {
      align: 'top',
      duration: 200,
      offset: 0,
    });
  }

  render() {
    const { employees, id } = this.props;
    const { isEmployeeProfileOpenStyle, isMobile } = this.state;

    const flickityOptions = {
      contain: true,
      wrapAround: true,
      pageDots: false,
      adaptiveHeight: true,
      initialIndex: isMobile ? Math.floor(Math.random() * 4) + 1 : 0,
      cellAlign: isMobile ? 'center' : 'left',
    };

    if (!employees.length) {
      return null;
    }

    return (
      <SliderSection
        id={id}
        className={`${bgGradientBlueLight} ${
          isEmployeeProfileOpenStyle ? employeeProfileOpenStyle : ''
        }`}
      >
        {isEmployeeProfileOpenStyle && (
          <ButtonBackStyled onClick={this.handleEmployeeProfileCloseClick} />
        )}
        <SliderFlickity
          options={flickityOptions}
          flickityRef={r => (this.teamSlider = r)}
        >
          {employees.map((employee, index) => (
            <Slide key={employee.id}>
              <Person
                index={index}
                employee={employee}
                employeeNameClick={this.handleEmployeeNameClick}
              />
              {isEmployeeProfileOpenStyle && (
                <ProfileCard
                  employee={employee}
                  teamSliderResize={this.teamSliderResize}
                />
              )}
            </Slide>
          ))}
        </SliderFlickity>
      </SliderSection>
    );
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query TeamSliderQuery {
        craft {
          entries(section: [employees]) {
            id
            ... on Craft_Employees {
              employeeName
              employeeImage {
                url
                full: url(crop: { height: 1000, quality: 100 })
              }
              employeeVita {
                content
              }
              employeePublications {
                content
              }
            }
          }
        }
      }
    `}
    render={data => <TeamSlider employees={data?.craft?.entries} {...props} />}
  />
);
